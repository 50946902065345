import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header className="header">
    <div className="header__logo">
      <p className="header__crown"><Link to={`/`} className="header__crownLink"><StaticImage src="../images/logo.png" alt="ロゴ" quality={100} layout="constrained" placeholder="dominantColor" className="header__image" /></Link></p>
      <h1 className="header__title"><Link to={`/`} className="header__titleLink">Poncotsu Club</Link></h1>
      <p className="header__subtitle"><Link to={`/`} className="header__subtitleLink">by the poncotsu, for the poncotsu</Link></p>
    </div>
    <nav className="header__navigation">
      <ul className="header__list">
        <li className="header__item1"><Link to={`/`} className="header__link">トップ</Link></li>
        <li className="header__item2"><Link to={`/about/`} className="header__link">ぽんこつクラブとは</Link></li>
        <li className="header__item3"><Link to={`/content/`} className="header__link">コンテンツ</Link></li>
      </ul>
    </nav>
  </header>
)

export default Header